// src/Pages/CustomerBadgePage.js

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
`;

const Title = styled.h1`
  color: #333;
`;

const BackLink = styled(Link)`
  margin-top: 20px;
  text-decoration: none;
  color: #007bff;
  font-size: 18px;
`;

const CustomerBadgePage = () => {
  return (
    <PageContainer>
      <Title>Hello from Customer Badge Page</Title>
      <BackLink to="/">Back to Home</BackLink>
    </PageContainer>
  );
};

export default CustomerBadgePage;
