import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Card = styled.div`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  cursor: pointer;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;

const CardImage = styled.img`
  max-width: 100%;
  border-radius: 8px;
`;

const CardTitle = styled.h2`
  margin: 10px 0;
`;

const MenuCard = ({ title, imageSrc, link }) => (
  <Link to={link} style={{ textDecoration: 'none' }}>
    <Card>
      <CardImage src={imageSrc} alt={title} />
      <CardTitle>{title}</CardTitle>
    </Card>
  </Link>
);

export default MenuCard;
