import React from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import MenuCard from '../components/MenuCard';
import menuImage from '../Images/menu.jpeg';
import salesImage from '../Images/sales.jpg';
import customerImage from '../Images/customer.jpg';

const Container = styled.div`
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
`;

const Main = styled.main`
  padding: 20px;
  text-align: center;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(150px, 1fr));
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Footer = styled.footer`
  background-color: #282c34;
  padding: 10px;
  color: white;
  text-align: center;
  width: 100%;
  bottom: 0;
  position: relative;
`;

const HomePage = () => {
  return (
    <Container>
      <Navbar />
      <Main>
        <h1>Xero Degrees Dashboard - powered by ConQR</h1>
        <CardGrid>
          <MenuCard title="Menu Management" imageSrc={menuImage} link="/menu-management" />
          <MenuCard title="Sales" imageSrc={salesImage} link="/sales" />
          <MenuCard title="Customer Badge" imageSrc={customerImage} link="/customer-badge" />
        </CardGrid>
      </Main>
      <Footer>
        &copy; 2024 ConQR. All rights reserved.
      </Footer>
    </Container>
  );
}

export default HomePage;
