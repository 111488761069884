import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../src/components/HomePage';
import MenuManagementPage from '../src/components/MenuManagement';
import SalesPage from '../src/components/Sales';
import CustomerBadgePage from '../src/components/CustomerBadge';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/menu-management" element={<MenuManagementPage />} />
        <Route path="/sales" element={<SalesPage />} />
        <Route path="/customer-badge" element={<CustomerBadgePage />} />
      </Routes>
    </Router>
  );
};

export default App;
