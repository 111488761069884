// src/components/Navbar.js

import React from 'react';
import styled from 'styled-components';
import logo from '../Images/xero-logo.png';

const Nav = styled.nav`
  background-color: #333;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Logo = styled.img`
  height: 40px;
  width: auto;
`;

const Navbar = () => {
  return (
    <Nav>
      <Logo src={logo} alt="Logo" />
    </Nav>
  );
};

export default Navbar;
