import React, { useState } from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';  // Make sure this path is correct
import { Link } from 'react-router-dom';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align all content horizontally */
  justify-content: flex-start; /* Align content to the top */
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  padding-top: 80px; /* Add padding to account for Navbar height and extra space */
  box-sizing: border-box; /* Include padding in the element's total width and height */
`;

const Title = styled.h1`
  color: #333;
  margin: 20px 0; /* Margin to create space around the title */
`;

const BackLinkContainer = styled.div`
  position: absolute;
  top: 80px; /* Add extra space below the Navbar */
  left: 20px;
  font-size: 18px;
`;

const BackLink = styled(Link)`
  text-decoration: none;
  color: #007bff;
`;

const AddCategoryButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
  padding: 20px;
  box-sizing: border-box; /* Include padding in the element's total width and height */
`;

const AddCategoryButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 20px; /* Adjust this value to move the button slightly to the left */
  
  &:hover {
    background-color: #0056b3;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const PopupContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
`;

const PopupTitle = styled.h2`
  margin-bottom: 15px;
`;

const PopupInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: calc(100% - 22px); /* Adjust width to include padding and borders */
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  text-align: center; /* Center align placeholder text */
`;

const PopupButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
`;

const CloseButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #dc3545;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const CategoriesList = styled.div`
  display: flex;
  flex-wrap: nowrap; /* Keep items in a single row */
  overflow-x: hidden; /* Hide horizontal overflow */
  margin-top: 20px;
  padding: 0;
  list-style: none;
`;

const CategoryItem = styled.div`
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px;
  color: ${props => (props.isSelected ? '#007bff' : '#333')};
  white-space: nowrap;

  &:hover {
    color: #007bff;
  }

  &::after {
    content: '';
    display: ${props => (props.isSelected ? 'block' : 'none')};
    height: 2px;
    background-color: #007bff;
    margin-top: 5px;
    border-radius: 2px;
  }
`;

const AddMenuButton = styled.button`
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #218838;
  }
`;

const NoCategoriesMessage = styled.div`
  margin: 20px;
  padding: 20px;
  background-color: #fff3cd;
  color: #856404;
  border: 1px solid #ffeeba;
  border-radius: 5px;
  text-align: center;
`;

const ItemsTable = styled.div`
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  border: 1px solid #ddd;
`;

const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  font-weight: bold;
  background-color: #f8f9fa;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const TableCell = styled.div`
  padding: 5px;
`;

const MenuManagementPage = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [items, setItems] = useState({});
  const [isAddMenuPopupOpen, setAddMenuPopupOpen] = useState(false);
  const [menuName, setMenuName] = useState('');
  const [menuPrice, setMenuPrice] = useState('');

  const handleAddCategoryClick = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
    setCategoryName(''); // Clear input on close
  };

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleAddCategory = () => {
    if (categoryName.trim()) {
      const newCategory = categoryName.trim();
      // Check for duplicates
      if (!categories.includes(newCategory)) {
        setCategories(prevCategories => {
          const updatedCategories = [...prevCategories, newCategory];
          // Set the first added category as selected if no category is selected
          if (selectedCategory === '') {
            setSelectedCategory(newCategory);
          }
          return updatedCategories;
        });
        setItems(prevItems => ({ ...prevItems, [newCategory]: [] }));
        setCategoryName(''); // Clear input after adding
        handleClosePopup();
      } else {
        alert('Category already exists.');
      }
    }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const handleAddMenuClick = () => {
    if (selectedCategory) {
      setAddMenuPopupOpen(true);
    } else {
      alert('Please select a category first.');
    }
  };

  const handleCloseAddMenuPopup = () => {
    setAddMenuPopupOpen(false);
    setMenuName('');
    setMenuPrice('');
  };

  const handleMenuNameChange = (e) => {
    setMenuName(e.target.value);
  };

  const handleMenuPriceChange = (e) => {
    setMenuPrice(e.target.value);
  };

  const handleAddMenu = () => {
    if (menuName.trim() && menuPrice.trim()) {
      const newItem = { name: menuName.trim(), price: menuPrice.trim() };
      setItems(prevItems => {
        const updatedItems = [...(prevItems[selectedCategory] || [])];
        updatedItems.push(newItem);
        return { ...prevItems, [selectedCategory]: updatedItems };
      });
      handleCloseAddMenuPopup();
    } else {
      alert('Please fill in all fields.');
    }
  };

  return (
    <div>
      <Navbar />
      <PageContainer>
        <BackLinkContainer>
          <BackLink to="/">Back to Home</BackLink>
        </BackLinkContainer>
        <Title>Menu Management</Title>
        <AddCategoryButtonContainer>
          <AddCategoryButton onClick={handleAddCategoryClick}>Add Category</AddCategoryButton>
        </AddCategoryButtonContainer>
        {categories.length === 0 ? (
          <NoCategoriesMessage>
            Please add a category to add, update, or delete menu items.
          </NoCategoriesMessage>
        ) : (
          <>
            <CategoriesList>
              {categories.map((category, index) => (
                <CategoryItem
                  key={index}
                  onClick={() => handleCategoryClick(category)}
                  isSelected={category === selectedCategory}
                >
                  {category}
                </CategoryItem>
              ))}
            </CategoriesList>
            <AddMenuButton onClick={handleAddMenuClick}>
              Add {selectedCategory ? `"${selectedCategory}"` : 'Menu Item'}
            </AddMenuButton>
            {selectedCategory && (
              <>
                <ItemsTable>
                  <TableHeader>
                    <TableCell>Item Name</TableCell>
                    <TableCell>Price (INR)</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableHeader>
                  {items[selectedCategory] && items[selectedCategory].length > 0 ? (
                    items[selectedCategory].map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.price}</TableCell>
                        <TableCell>
                          <button>Edit</button> {/* Implement edit functionality */}
                          <button>Delete</button> {/* Implement delete functionality */}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan="3">No items available</TableCell>
                    </TableRow>
                  )}
                </ItemsTable>
              </>
            )}
          </>
        )}
      </PageContainer>
      {isPopupOpen && (
        <PopupOverlay>
          <PopupContainer>
            <PopupTitle>Add New Category</PopupTitle>
            <PopupInput
              type="text"
              placeholder="Enter category name"
              value={categoryName}
              onChange={handleCategoryNameChange}
            />
            <div>
              <PopupButton onClick={handleAddCategory}>Add</PopupButton>
              <CloseButton onClick={handleClosePopup}>Close</CloseButton>
            </div>
          </PopupContainer>
        </PopupOverlay>
      )}
      {isAddMenuPopupOpen && (
        <PopupOverlay>
          <PopupContainer>
            <PopupTitle>Add Menu Item</PopupTitle>
            <PopupInput
              type="text"
              placeholder="Enter item name"
              value={menuName}
              onChange={handleMenuNameChange}
            />
            <PopupInput
              type="text"
              placeholder="Enter price (INR)"
              value={menuPrice}
              onChange={handleMenuPriceChange}
            />
            <div>
              <PopupButton onClick={handleAddMenu}>Add</PopupButton>
              <CloseButton onClick={handleCloseAddMenuPopup}>Close</CloseButton>
            </div>
          </PopupContainer>
        </PopupOverlay>
      )}
    </div>
  );
};

export default MenuManagementPage;
